export const devQuoteData = {
    marketCode: "Broker",
    brand: "Spot",
    firstName: "Test",
    lastName: "User",
    phoneNumber: "5453214433",
    pets: [
        {
            name: "Test Pet",
            birthDate: "2023-06-05",
            breed: {
                code: "Dog~Mixed~Beagle%20Mix",
                name: "Beagle Mix",
                species: "Dog"
            },
            gender: "M",
            quotePolicySelections: {
                base: { code: "Level2~Deductible1000~Copay10" },
                annualLimit: { limit: "LimitUnlimited" },
                wellness: { code: "Low" }
            }
        },
        {
            name: "Test Pet2",
            birthDate: "2023-06-05",
            breed: {
                code: "Dog~Mixed~Beagle%20Mix",
                name: "Beagle Mix",
                species: "Dog"
            },
            gender: "M",
            quotePolicySelections: {
                base: { code: "Level2~Deductible1000~Copay10" },
                annualLimit: { limit: "LimitUnlimited" },
                wellness: { code: "Low" }
            }
        }
    ],
    billingInformation: {
        nameOnAccount: "Test User",
        billingType: "CreditCard",
        accountNumber: "4242424242424242",
        billingFrequency: "Annually",
        creditCardType: "Visa",
        expirationDate: { month: "April", year: 2050 }
    },
    billingAddress: {
        street1: "1106 Marlborough St",
        city: "Philadelphia",
        zipcode: "19125",
        state: "PA"
    },
    ratingAddress: {
        street1: "1106 Marlborough St",
        city: "Philadelphia",
        zipcode: "19125",
        state: "PA"
    },
    mailingAddress: {
        street1: "1106 Marlborough St",
        city: "Philadelphia",
        zipcode: "19125",
        state: "PA"
    },
    priorityCode: "SPOT_WAG1",
    allowMarketing: true,
    cvv: "111",
    emailAddress: "spotpet-ajadg6_KLaXaVoiL4r-Dg@mailinator.com"
};
