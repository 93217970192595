import * as React from "react";
import { Select, type SelectOption } from "./Select";
import { Label } from "./label";
import { cva, VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";
import { ErrorMessage } from "../FormField";

const selectLabelVariants = cva("relative flex", {
    variants: {
        variant: {
            default: "",
            floating: ""
        }
    },
    defaultVariants: {
        variant: "default"
    }
});

interface SelectWithLabelProps extends VariantProps<typeof selectLabelVariants> {
    id?: string;
    label: string;
    labelId?: string;
    value: string | undefined;
    options: SelectOption[];
    placeholder?: string;
    onValueChange: (value: string) => void;
    error?: ErrorMessage;
    disabled?: boolean;
    wrapperClass?: string;
    selectClass?: string;
    triggerProps?: React.HTMLAttributes<HTMLButtonElement>;
    constrainWidthToTrigger?: boolean;
}

const SelectWithLabel = React.forwardRef<HTMLDivElement, SelectWithLabelProps>((props, ref) => {
    const {
        id,
        label,
        labelId,
        value,
        options,
        placeholder,
        onValueChange,
        error,
        disabled,
        wrapperClass,
        selectClass,
        triggerProps,
        constrainWidthToTrigger = false,
        variant
    } = props;

    const selectId = !id ? label?.split(" ").join("-").toLowerCase() : id;
    const isDefaultVariant = variant === "default" || variant === undefined;
    const shouldShowLabel = isDefaultVariant || (variant === "floating" && value !== undefined);

    const labelClassConditions = {
        "absolute -top-6": isDefaultVariant,
        "text-xs": !isDefaultVariant
    };

    return (
        <div className={cn(selectLabelVariants({ variant }), wrapperClass)}>
            {shouldShowLabel && (
                <Label id={labelId} htmlFor={selectId} variant={variant} bgColor={isDefaultVariant ? "transparent" : "default"} className={cn(labelClassConditions)}>
                    {label}
                </Label>
            )}
            <Select
                ref={ref}
                value={value}
                options={options}
                placeholder={placeholder}
                onValueChange={onValueChange}
                error={error}
                disabled={disabled}
                className={selectClass}
                triggerProps={{
                    id: selectId,
                    "aria-labelledby": labelId,
                    ...triggerProps
                }}
                constrainWidthToTrigger={constrainWidthToTrigger}
            />
        </div>
    );
});

SelectWithLabel.displayName = "SelectWithLabel";

export { SelectWithLabel };
