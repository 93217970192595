// ErrorSelect.tsx
import React, { useState } from "react";
import { Select } from "../ui/Select";
import { Label } from "@/shared/components/ui/label";
import { ErrorIdType } from "@/shared/types/SpotAPI";
import { Button } from "../ui";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";

export const ErrorSelect = () => {
    const { appState, updateAppState } = useAppLayerContext();
    const { mockAPIError } = appState;

    const availableErrors = [
        "priority-code-deprecated",
        "invalid-postal-code",
        "invalid-postal-code-formatting",
        "invalid-microchip",
        "invalid-coverage",
        "location-not-available",
        "invalid-credit-card-number",
        "invalid-credit-card-declined",
        "invalid-billing-name"
    ];
    // todo: use allSpotErrorIds when all error ids are available via https://dev-api.spotpet.com/?sim=help
    const options = availableErrors.map(id => ({ value: id, label: id })) as { value: ErrorIdType; label: string }[];
    const [selectedErrorId, setSelectedErrorId] = useState<ErrorIdType | undefined>(mockAPIError);

    const handleMockAPIErrorChange = (errorId: string) => {
        setSelectedErrorId(errorId as ErrorIdType);
        updateAppState({ mockAPIError: errorId as ErrorIdType });
    };

    const clearAPIError = () => {
        setSelectedErrorId(undefined);
        updateAppState({ mockAPIError: undefined });
    };

    return (
        <div className="w-auto">
            <Label id="force-api-error-label" htmlFor="force-api-error" className="mb-0 bg-background-transparent text-sm">
                Force API Error
            </Label>
            <div className="flex items-center gap-2">
                <Select
                    triggerProps={{ id: "force-api-error" }}
                    key={selectedErrorId}
                    placeholder="Select Error ID"
                    value={selectedErrorId}
                    options={options}
                    onValueChange={handleMockAPIErrorChange}
                />
                {!!selectedErrorId && (
                    <Button variant="destructive" disabled={!selectedErrorId} onClick={clearAPIError}>
                        Clear
                    </Button>
                )}
            </div>
        </div>
    );
};

ErrorSelect.displayName = "ErrorSelect";
