import { cva } from "class-variance-authority";
import { cn } from "../utils";

export interface LoadingSpinnerProps {
    className?: string;
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
    color?: "default";
}

const spinnerVariants = cva("flex shrink-0 animate-spin rounded-full", {
    variants: {
        size: {
            xs: "size-4 border-2",
            sm: "size-6 border-[3px]",
            md: "size-10 border-[6px]",
            lg: "size-14 border-8",
            xl: "size-20 border-[10px]",
            "2xl": "size-24 border-[12px]"
        },
        color: {
            default: "border-stroke-primary border-b-stroke-secondary-invert"
        }
    },
    defaultVariants: {
        size: "md",
        color: "default"
    }
});

function LoadingSpinner(props: LoadingSpinnerProps) {
    const { size, color, className } = props;
    return <span role="progressbar" aria-label="Loading" className={cn(spinnerVariants({ size, color, className }))}></span>;
}

export default LoadingSpinner;
