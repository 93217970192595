import React, { useState } from "react";
import { Button } from "../ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagic } from "@fortawesome/pro-solid-svg-icons";
import { UIUtils } from "@/shared/utils/UIUtils";
import { PublicConfig } from "@/shared/PublicConfig";
import { useRouter } from "next/navigation";
import { TransformUtils } from "spot-types/utilities/TransformUtils";
import { PTCQuoteTransforms } from "spot-types/vendor/ptz/PTZQuoteTransforms";
import axios from "axios";
import { devQuoteData } from "./dev-tools.constants";
import LoadingSpinner from "../LoadingSpinner";

const CheckoutButton = () => {
    const router = useRouter();
    const [isLoading, setisLoading] = useState(false);
    const loadingIcon = isLoading ? <LoadingSpinner size="sm" /> : <FontAwesomeIcon icon={faMagic} size="lg" />;

    const createFinalizeQuote = async () => {
        const targetUrl = `${PublicConfig.PTZ_US.UNDERWRITER_API_URL}/quotes/createandfinalize`;
        const headers = { "X-ApiKey": "5897AF00-93F7-42A1-9E3D-D456189A4DCD" };

        try {
            setisLoading(true);
            const quote = await axios.post(targetUrl, devQuoteData, { headers: headers });

            if (quote?.data) {
                const updatedQuote = await TransformUtils.transformJSON(quote.data, PTCQuoteTransforms.PTZQuoteToPetQuote);
                return updatedQuote;
            }
        } catch (error) {
            setisLoading(false);
            console.error("Error on 'createandfinalize' quote: ", error);
        }
        setisLoading(false);
        return {};
    };

    const handleCheckout = async () => {
        try {
            const quote = await createFinalizeQuote();

            if (quote?.quoteId && quote?.underwriter) {
                const params = UIUtils.toURLSearchParams({ uw: `${quote?.underwriter}`, quoteId: `${quote?.quoteId}` });
                const newURL = UIUtils.toUrlString(`${PublicConfig.BASE_PATH}/forms/${PublicConfig.PTZ_US.FORM_ID}/thankyou`, params);
                router.push(newURL);
            }
        } catch (error) {
            throw new Error("Quote create failed");
        }
    };

    return (
        <Button className="w-full" onClick={handleCheckout} startDecorator={loadingIcon} disabled={isLoading}>
            Checkout
        </Button>
    );
};

export default CheckoutButton;
